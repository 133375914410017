import { ErrorPage, LoadingPage, Title } from "components";
import { Link, useParams } from "react-router-dom";
import useSWR from "swr";
import { get_project, get_profile, get_project_calls, get_general } from "api";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { PageHelp } from "@/components/styled/pageHelp";

import { IoMdThumbsUp, IoMdThumbsDown } from "react-icons/io";

import { useToast } from "@/hooks/use-toast";
import { ToastAction } from "@/components/ui/toast";
import { Toaster } from "@/components/ui/toaster";
import { provide_feedback_for_recommended_calls } from "api/grant_space";
import Search from "./Search";
import Results from "./Results";

export default function RecommendedCalls() {
  const { projectId } = useParams();

  const {
    data: project,
    isLoading: isProjectLoading,
    error: isProjectError,
  } = useSWR(`get_project/${projectId}`, () =>
    get_project({ project_id: projectId })
  );

  const {
    data: profile,
    isLoading: isProfileLoading,
    error: isProfileError,
  } = useSWR(`get_profile`, () => get_profile());

  const {
    data: calls,
    isLoading: isCallsLoading,
    error: isCallsError,
  } = useSWR(`get_project_calls/${projectId}`, async () => {
    const response = await get_project_calls({ project_id: projectId });
    if (typeof response === "string")
      return { recommendedCalls: [], bookmarkedCalls: [] };
    const { calls } = response;
    const recommendedCalls = calls.filter((c) => !c.bookmarked);
    const bookmarkedCalls = calls.filter((c) => c.bookmarked);
    if (recommendedCalls && recommendedCalls.length > 0) {
      setIsShowingResults(true);
    }
    return {
      recommendedCalls: recommendedCalls,
      bookmarkedCalls: bookmarkedCalls,
    };
  });

  const {
    data: researchIdea,
    isLoading: isResearchIdeaLoading,
    error: isResearchIdeaError,
  } = useSWR(`/project/idea/general/${projectId}`, () =>
    get_general({ project_id: projectId, tile_id: 4 })
  );

  const [isShowingResults, setIsShowingResults] = useState(
    calls?.recommendedCalls?.length > 0 || false
  );

  if (
    isCallsLoading ||
    isResearchIdeaLoading ||
    isProjectLoading ||
    isProfileLoading
  )
    return <LoadingPage />;
  if (isCallsError || isResearchIdeaError || isProjectError || isProfileError)
    return <ErrorPage />;

  const hasEditorAccess =
    project?.users.find((p) => p.user_id === profile.user_id).relationship !==
    "viewer";

  return (
    <div className="bg-white grow rounded-lg h-full p-4">
      <div className="flex flex-col h-full gap-y-4">
        <div className="flex justify-between">
          <Title className="text-4xl" value="Recommended Calls" />
          <PageHelp
            title="Grant Space"
            content={
              <div className="flex flex-col gap-y-4 text-black">
                <div>
                  The Grant Space is dedicated to discovering and managing
                  funding opportunities.
                </div>

                <div>
                  <h2 className="font-semibold">Selected Call</h2>
                  View all details about the grant you are applying for. If you
                  haven't selected a call yet, you can do so here.
                </div>
                <div>
                  <h2 className="font-semibold">Recommend Calls</h2>
                  Using the information you provided in the Research Idea and
                  Background Material sections of the Ideation Space, we will
                  suggest funding opportunities we think may be relevant to you.
                </div>
                <div>
                  <h2 className="font-semibold">Search For Calls</h2>A search
                  tool for finding calls.
                </div>
                <div>
                  <h2 className="font-semibold">Bookmarked Calls</h2>
                  Keep track of important calls by bookmarking them. This
                  section shows all calls bookmarked by you and any other
                  collaborators on the project.
                </div>
              </div>
            }
          />
        </div>
        {!researchIdea?.idea || !researchIdea?.description ? (
          <div>
            Please fill out your{" "}
            <Link
              className="text-fuchsia hover:underline"
              to={`/project/${projectId}/ideation-space`}
            >
              research idea and description
            </Link>{" "}
            to get recommended calls.
          </div>
        ) : (
          <>
            {isShowingResults ? (
              <Results
                projectId={projectId}
                calls={calls}
                setIsShowingResults={setIsShowingResults}
              />
            ) : (
              <Search
                projectId={projectId}
                calls={calls}
                setIsShowingResults={setIsShowingResults}
              />
            )}
          </>
        )}
      </div>
    </div>
  );
}
