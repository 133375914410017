import { finalize_session, get_brainstorm_session_info } from "api";
import { ErrorPage, LoadingPage } from "components";
import { Link, useParams } from "react-router-dom";
import useSWR, { mutate } from "swr";
import useSWRMutation from "swr/mutation";
import EditBrainstormSessionInfo from "./EditBrainstormSessionInfo";
import CreateFollowupSession from "./CreateFollowupSession";
import { Tabs } from "@/components/styled/tabs";
import { FaEllipsisV } from "react-icons/fa";
import { PageHelp } from "@/components/styled/pageHelp";
import { FaArrowLeft } from "react-icons/fa";
import ChatWindow from "./ChatWindow";

export default function BrainstormSession() {
  const { projectId, sessionId } = useParams();

  const {
    data: sessionInfo,
    isLoading: isGettingSessionInfo,
    error: sessionInfoError,
    isValidating: isValidatingSessionInfo,
  } = useSWR(`get_brainstorm_session_info/${sessionId}`, () =>
    get_brainstorm_session_info({ session_id: sessionId })
  );

  const {
    isMutating: isFinalizingSession,
    error: finalizeSessionError,
    trigger: finalizeSession,
  } = useSWRMutation(
    `finalize_session`,
    () =>
      finalize_session({
        session_id: sessionId,
      }),
    {
      onSuccess: () => {
        mutate(
          (key) =>
            key?.startsWith(`get_finished_brainstorm_sessions/${projectId}`),
          (sessions) =>
            sessions.length < 15
              ? [
                  { ...sessionInfo, session_id: sessionId, finished: true },
                  ...sessions,
                ]
              : sessions
        );
        mutate(
          (key) => key?.startsWith(`get_brainstorm_sessions/${projectId}`),
          (sessions) =>
            sessions.map((session) =>
              session.session_id === sessionId
                ? { ...session, finished: true }
                : session
            )
        );
        mutate(`get_brainstorm_session_info/${sessionId}`);
      },
    }
  );

  if (isFinalizingSession || isValidatingSessionInfo || isGettingSessionInfo)
    return <LoadingPage />;
  if (sessionInfoError) return <ErrorPage />;
  if (!sessionInfo) return <></>;

  const {
    creator_name,
    agent_1_background,
    agent_2_background,
    title,
    finished,
    summary,
    requestor_is_creator,
  } = sessionInfo;

  return (
    <div className="bg-white grow rounded-lg flex flex-col p-4 w-full gap-y-4 h-full">
      <div className="border-b-2 border-fuchsia">
        <div className="flex justify-between items-center">
          <div className="flex items-center gap-x-2">
            <Link
              to={`/project/${projectId}/ideation-space/brainstorm-session`}
              className="hover:text-fuchsia"
            >
              <FaArrowLeft />
            </Link>
            <EditBrainstormSessionInfo
              projectId={projectId}
              sessionId={sessionId}
              title={title}
              trigger={<div className="text-2xl rounded-md">{title}</div>}
            />
          </div>
          <div className="flex items-center">
            {!finished && requestor_is_creator && (
              <button
                className="btn-fuchsia text-base"
                disabled={isFinalizingSession}
                onClick={finalizeSession}
              >
                Finalize Session
              </button>
            )}
            <EditBrainstormSessionInfo
              projectId={projectId}
              sessionId={sessionId}
              title={title}
              trigger={
                <span className="hover:cursor-pointer">
                  <FaEllipsisV />
                </span>
              }
            />
            <PageHelp
              title="Ideation Space"
              content={
                <div className="flex flex-col gap-y-4 text-black">
                  <div>
                    The Ideation Space is designed to help you refine your
                    research idea.
                  </div>
                  <div>
                    <h2 className="font-semibold">Research Idea</h2>
                    Here you will tell us a more about what idea you would like
                    to get funded.
                  </div>
                  <div>
                    <h2 className="font-semibold">Background Material</h2>
                    Enhance our understanding of your idea by searching for and
                    linking relevant papers. You can search for papers using
                    Arxiv or Semantic Scholar. Note: adding papers here will
                    help us to recommend better calls for you in the future.
                  </div>
                  <div>
                    <h2 className="font-semibold">Brainstorming Ideas</h2>
                    Use this section to further develop your idea, take notes,
                    and explore new perspectives. You can manually add ideas to
                    the brainstorming list, or let us generate suggestions for
                    you. Ideas are generated based on your Research Idea,
                    Description, and any Background Materials you've provided.
                  </div>
                  <div>
                    <h2 className="font-semibold">Brainstorming Sessions</h2>
                    With Sessions, you can easily create brainstorming rooms
                    with experts from various backgrounds. Each session lasts a
                    few days, and after it ends, we'll provide a summary of the
                    discussion. You can start new sessions based on previous
                    topics or begin with a fresh idea.
                  </div>
                </div>
              }
            />
          </div>
        </div>
        <div className="text-base text-ellipsis">
          <span className="">{creator_name}, </span>
          <span className="capitalize">
            Researcher 1 ({agent_1_background})
          </span>
          <span>, </span>
          <span className="capitalize">
            Researcher 2 ({agent_2_background})
          </span>
        </div>
      </div>
      {finished ? (
        <Tabs
          defaultValue="summary"
          tabs={[
            {
              label: "Summary",
              value: "summary",
              content: (
                <div className="text-base bg-pastel-blue rounded-md p-4 overflow-y-scroll">
                  {summary}
                </div>
              ),
            },
            {
              label: "Messages",
              value: "messages",
              content: (
                <>
                  <div className="text-base">
                    Note: These messages will disappear after a while.
                  </div>
                  <ChatWindow
                    creator_name={creator_name}
                    sessionInfo={sessionInfo}
                    sessionId={sessionId}
                    finished={finished}
                    requestor_is_creator={requestor_is_creator}
                  />
                </>
              ),
            },
          ]}
        />
      ) : (
        <ChatWindow
          creator_name={creator_name}
          sessionInfo={sessionInfo}
          sessionId={sessionId}
          finished={finished}
          requestor_is_creator={requestor_is_creator}
        />
      )}

      {finished && requestor_is_creator && (
        <CreateFollowupSession sessionId={sessionId} projectId={projectId} />
      )}
    </div>
  );
}
