import { Dialog } from "@/components/styled/dialog";
import { Input } from "@/components/styled/input";
import { Select } from "@/components/styled/select";
import { create_followup_session } from "api";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import useSWRMutation from "swr/mutation";

const agent_types = [
  { label: "Sociology", value: "sociology" },
  { label: "Computer Science", value: "computer science" },
  { label: "Data Science", value: "data science" },
  { label: "Economics", value: "economics" },
  { label: "Mechnical Engineering", value: "mechanical engineering" },
  { label: "Chemical Engineering", value: "chemical engineering" },
  { label: "Aerospace Engineering", value: "aerospace engineering" },
  { label: "Statistics", value: "statistics" },
  { label: "Mathematics", value: "mathematics" },
  { label: "Civil Engineering", value: "civil engineering" },
  { label: "Medical", value: "medical" },
  { label: "Computer Engineering", value: "computer engineering" },
  { label: "Computer Biology", value: "biology" },
  { label: "Chemistry", value: "chemistry" },
  { label: "Physics", value: "physics" },
];

export default function CreateFollowupSession({ sessionId, projectId }) {
  const navigate = useNavigate();
  const {
    isMutating: isCreatingFollowupSession,
    error: createFollowupSessionError,
    trigger: createFollowupSession,
  } = useSWRMutation(
    `create_followup_session`,
    () =>
      create_followup_session({
        project_id: projectId,
        session_id: sessionId,
        name: sessionTitle,
        agent_1_background: agent1Background,
        agent_2_background: agent2Background,
      }),
    {
      onSuccess: (d) => {
        const { session_id } = d;
        navigate(
          `/project/${projectId}/ideation-space/brainstorm-session/${session_id}`
        );
        mutate(`get_brainstorm_sessions/${projectId}`, undefined, {
          revalidate: true,
        });
      },
    }
  );

  const [sessionTitle, setSessionTitle] = useState("");
  const [agent1Background, setAgent1Background] = useState("");
  const [agent2Background, setAgent2Background] = useState("");

  return (
    <div className="flex justify-center">
      <Dialog
        trigger={
          <span className="btn-fuchsia hover:opacity-80 hover:cursor-pointer rounded-2xl">
            Start Followup Session
          </span>
        }
        content={
          <div className="flex flex-col gap-y-4">
            <Input
              placeholder="Session Title"
              value={sessionTitle}
              onChange={setSessionTitle}
            />

            <Select
              options={agent_types}
              value={agent1Background}
              placeholder="Researcher 1 Expertise"
              onChange={setAgent1Background}
            />

            <Select
              options={agent_types}
              value={agent2Background}
              placeholder="Researcher 2 Expertise"
              onChange={setAgent2Background}
            />
            <div>
              <button
                onClick={createFollowupSession}
                disabled={!agent1Background || !agent2Background}
                className="btn-fuchsia"
              >
                Start Session
              </button>
            </div>
          </div>
        }
      />
    </div>
  );
}
