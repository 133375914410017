import { useEffect, useRef, useState } from "react";
import InitiumChatMessage from "./InitiumChatMessage";
import UserChatMessage from "./UserChatMessage";
import useSWRMutation from "swr/mutation";
import {
  get_brainstorm_session_messages,
  send_brainstorm_session_message,
} from "api";
import { Transition } from "@headlessui/react";
import { LoadingSpinner } from "components";
import { BsFillSendFill } from "react-icons/bs";

export default function ChatWindow({
  creator_name,
  sessionInfo,
  sessionId,
  finished,
  requestor_is_creator,
}) {
  const messagesPerPage = 15;
  const chatWindow = useRef(null);
  const bottomRef = useRef(null);
  const [message, setMessage] = useState("");
  const [displayedMessages, setDisplayedMessages] = useState([]);

  const previousScrollHeight = useRef(0);
  const [page, setPage] = useState(0);
  const [numMessagesSent, setNumMessagesSent] = useState(0); // Used to get previous messages correctly
  const [isLastPage, setIsLastPage] = useState(false); // Bool to check if we reached the last page

  const {
    trigger: getMessages,
    isMutating: isGettingBrainstormSessionsMessages,
    error: brainstormSessionsMessagesError,
  } = useSWRMutation(
    `get_brainstorm_session_messages`,
    (key, { arg }) =>
      get_brainstorm_session_messages({
        session_id: sessionId,
        start_number: arg * messagesPerPage + numMessagesSent,
      }),
    {
      onSuccess: (d) => {
        if (d.length < 15) setIsLastPage(true);
        setDisplayedMessages((messages) => [...d.toReversed(), ...messages]);
      },
    }
  );

  const {
    isMutating: isSendingMessage,
    error: sendMessageError,
    trigger: sendMessage,
  } = useSWRMutation(
    `send_brainstorm_session_message`,
    () =>
      send_brainstorm_session_message({
        session_id: sessionId,
        message: message,
      }),
    {
      onSuccess: (d) => {
        setNumMessagesSent((num) => num + d.length);
        setDisplayedMessages((messages) => [...messages, ...d]);
      },
    }
  );

  const handleScroll = (e) => {
    if (chatWindow?.current?.scrollTop === 0 && !isLastPage) {
      console.log(page * messagesPerPage + numMessagesSent);
      getMessages(page);
      previousScrollHeight.current = chatWindow.current.scrollHeight;
      setPage(page + 1);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (isSendingMessage) return;

    setNumMessagesSent((num) => num + 1);
    sendMessage(message);
    setDisplayedMessages((messages) => [
      ...messages,
      { message: message, sender: creator_name },
    ]);
    setMessage("");
  };

  useEffect(() => {
    if (chatWindow.current) {
      chatWindow.current.scrollTop =
        chatWindow.current.scrollHeight - previousScrollHeight.current;
    }
  }, [displayedMessages]);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [isSendingMessage]);

  useEffect(() => {
    getMessages(page).then(() => bottomRef.current?.scrollIntoView());
    setPage(page + 1);
  }, []);

  return (
    <div className="h-full flex flex-col overflow-y-auto">
      <div className="relative flex grow h-full overflow-y-auto shrink">
        <Transition
          show={isGettingBrainstormSessionsMessages}
          className="absolute bg-white top-0 w-full"
          enter="transition-transform duration-300"
          enterFrom="-translate-y-full"
          enterTo="translate-y-0"
          leave="transition-transform duration-300"
          leaveFrom="translate-y-0"
          leaveTo="-translate-y-full"
        >
          <div className="flex justify-center items-center gap-x-2 ">
            <LoadingSpinner /> Loading previous messages...
          </div>
        </Transition>
        <div
          ref={chatWindow}
          onScroll={handleScroll}
          className="grow flex flex-col gap-y-4 overflow-y-scroll p-4"
        >
          {displayedMessages.map((message, index) =>
            message.sender.startsWith("Researcher") ? (
              <InitiumChatMessage
                key={index}
                {...message}
                background={
                  message.sender === "Researcher 1"
                    ? sessionInfo.agent_1_background
                    : sessionInfo.agent_2_background
                }
                creator_name={creator_name}
              />
            ) : (
              <UserChatMessage
                key={index}
                {...message}
                className="bg-gray-200"
              />
            )
          )}
          {isSendingMessage && (
            <div className="w-2/3 flex gap-x-2 items-end">
              <div className="rounded-full bg-pastel-blue border-2 h-fit w-fit translate-y-1/2">
                <div className="w-8 h-8"></div>
              </div>
              <div className="bg-pastel-blue flex items-center gap-x-2 px-4 py-1 rounded-2xl rounded-es-none text-sm">
                <LoadingSpinner /> Loading...
              </div>
            </div>
          )}
          <div ref={bottomRef}></div>
        </div>
      </div>
      {!finished && requestor_is_creator && (
        <form className="flex w-full gap-x-2" onSubmit={handleSubmit}>
          <input
            className="border-offblack border-2 rounded-lg grow bg-light-grey px-2"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
          <button
            disabled={message.length === 0 || isSendingMessage}
            className="btn-fuchsia flex gap-x-2 items-center"
          >
            Send
            <BsFillSendFill />
          </button>
        </form>
      )}
    </div>
  );
}
