import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { create_project } from "api";
import { mutate } from "swr";
import { useState } from "react";

import useSWRMutation from "swr/mutation";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "@/components/ui/accordion";

import { LoadingSpinner } from "components";
import { Input } from "@/components/styled/input";
import { Dialog } from "@/components/styled/dialog";
import { ScrollArea } from "@/components/ui/scroll-area";

export default function MainNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const [projectName, setProjectName] = useState("");

  const {
    isMutating: isCreatingProject,
    error: creatingProjectError,
    trigger: createProject,
  } = useSWRMutation(
    `add_users`,
    () => create_project({ name: projectName }),

    {
      onSuccess: (response) => {
        const { project_id } = response;
        mutate("count_project", undefined, { revalidate: false });
        mutate((key) => key?.startsWith("list_projects"), undefined, {
          revalidate: false,
        });
        setProjectName("");
        navigate(`/project/${project_id}`);
      },
    }
  );

  return (
    <ScrollArea className="h-full flex flex-col gap-y-2">
      <div className="flex flex-col gap-y-2">
        <Dialog
          asChild
          trigger={
            <button className="flex w-full justify-center items-center nav-offwhite font-semibold px-4">
              + New Project
            </button>
          }
          title="Create a new project"
          content={
            <div className="flex flex-col gap-y-4">
              <Input
                placeholder={"Project Name"}
                value={projectName}
                onChange={setProjectName}
              />
              <div className="flex gap-4 w-full justify-between">
                <button
                  className="btn-fuchsia flex items-center gap-x-1"
                  onClick={createProject}
                  disabled={isCreatingProject}
                >
                  {!isCreatingProject ? (
                    "Create Project"
                  ) : (
                    <>
                      Creating Project <LoadingSpinner />
                    </>
                  )}
                </button>
              </div>
              {creatingProjectError && (
                <span className="text-red-500">
                  Something went wrong, please try again.
                </span>
              )}
            </div>
          }
        />
        <NavLink className="nav-link m-1" to="/" end>
          Projects
        </NavLink>
        <NavLink className="nav-link m-1" to="/calls/guidelines-assistant" end>
          Guidelines Assistant
        </NavLink>
        <Accordion type="multiple" defaultValue={["profile"]}>
          <AccordionItem value="profile">
            <AccordionTrigger className="px-2 py-1 text-base underline font-semibold rounded-2xl">
              Profile
            </AccordionTrigger>
            <AccordionContent className="p-1 flex flex-col gap-y-2">
              <NavLink className="nav-link" to={"/profile"} end>
                About You
              </NavLink>
              <NavLink
                className="nav-link"
                to={"/profile/bookmarked-calls"}
                end
              >
                Bookmarks
              </NavLink>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </ScrollArea>
  );
}
