import { upload_paper_via_file, upload_paper_via_link } from "api";
import { LoadingSpinner } from "components";
import useSWRMutation from "swr/mutation";
import UploadPaperViaLink from "./UploadPaperViaLink";
import UploadPaperViaFile from "./UploadPaperViaFile";
import { useState } from "react";
import { mutate } from "swr";

export default function UploadPaperTab({ projectId }) {
  const [isGoogleStorageUploadError, setIsUploadError] = useState(false);

  const {
    data: linkData,
    isMutating: isLinkUploadMutating,
    error: isLinkUploadError,
    trigger: triggerLink,
    reset: resetLink,
  } = useSWRMutation(
    `upload_paper_via_link/${projectId}`,
    (key, { arg }) => {
      return upload_paper_via_link(arg);
    },
    {
      onSuccess: () => mutate(`get_papers?${projectId}`),
    }
  );
  const {
    data: fileData,
    isMutating: isFileUploadMutating,
    error: isFileUploadError,
    trigger: triggerFile,
    reset: resetFile,
  } = useSWRMutation(
    `upload_paper_via_file/${projectId}`,
    (key, { arg }) => {
      return upload_paper_via_file(arg);
    },
    {
      onSuccess: () => {
        console.log("uploaded");
        mutate(`get_papers?${projectId}`, undefined, { revalidate: true });
      },
    }
  );

  const clearUploadData = () => {
    setIsUploadError(false);
    resetLink();
    resetFile();
  };

  if (isLinkUploadMutating || isFileUploadMutating)
    return (
      <div className="flex flex-col items-center justify-center gap-y-4">
        <LoadingSpinner className="text-fuchsia h-40 w-40" />
        <span className="font-semibold">Uploading Paper</span>
      </div>
    );

  return (
    <div className="flex flex-col gap-y-4 mt-4">
      {(isLinkUploadError ||
        isFileUploadError ||
        isGoogleStorageUploadError) && (
        <span className="text-red-500">
          There was an issue uploading via link. Please try again.
        </span>
      )}

      {linkData || fileData ? (
        <>
          <p>Paper has been uploaded!</p>
          <button className="btn-fuchsia" onClick={clearUploadData}>
            Upload another paper
          </button>
        </>
      ) : (
        <>
          <UploadPaperViaLink projectId={projectId} trigger={triggerLink} />
          <div className="flex w-full items-center gap-x-4">
            <div className="w-full border-1 h-0"></div>
            <span className="font-semibold">OR</span>
            <div className="w-full border-1 h-0"></div>
          </div>
          <UploadPaperViaFile projectId={projectId} trigger={triggerFile} />
        </>
      )}
    </div>
  );
}
