import { Tooltip } from "@/components/styled/tooltip";
import { Input } from "@/components/styled/input";
import { DatePicker } from "@/components/ui/datepicker";
import Select from "react-select";
import { Switch } from "@/components/ui/switch";

import { format } from "date-fns";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { mutate } from "swr";
import { find_calls } from "api";
import { LoadingPage } from "components";
import { useFeedback } from "stores/useFeedbackStore";

const fundingOptions = [
  { value: "ACA", label: "Affordable Care Act" },
  { value: "AG", label: "Agriculture" },
  { value: "AR", label: "Arts" },
  { value: "BC", label: "Business and Commerce" },
  { value: "CD", label: "Community Development" },
  { value: "CP", label: "Consumer Protection" },
  { value: "DPR", label: "Disaster Prevention and Relief" },
  { value: "ED", label: "Education" },
  { value: "ELT", label: "Employment, Labor and Training" },
  { value: "EN", label: "Energy" },
  { value: "ENV", label: "Environment" },
  { value: "FN", label: "Food and Nutrition" },
  { value: "HL", label: "Health" },
  { value: "HO", label: "Housing" },
  { value: "HU", label: "Humanities" },
  { value: "ISS", label: "Income Security and Social Services" },
  { value: "IS", label: "Information and Statistics" },
  { value: "LJL", label: "Law, Justice and Legal Services" },
  { value: "NR", label: "Natural Resources" },
  { value: "RA", label: "Recovery Act" },
  { value: "RD", label: "Regional Development" },
  {
    value: "ST",
    label: "Science and Technology and other Research and Development",
  },
  { value: "T", label: "Transportation" },
  { value: "O", label: "Other" },
];

export default function Search({ projectId, calls, setIsShowingResults }) {
  const { setHasGivenFeedback } = useFeedback(projectId);

  const [grantFloor, setGrantFloor] = useState(0);
  const [closingDate, setClosingDate] = useState(
    new Date().toISOString().split("T")[0]
  );
  const [costSharing, setCostSharing] = useState(false);
  const [fundingCategory, setFundingCategory] = useState([]);

  const {
    isMutating: isFindCallsLoading,
    error: isFindCallsError,
    trigger: findCalls,
  } = useSWRMutation(
    `/process/idea/calls${projectId}`,
    () =>
      find_calls({
        project_id: projectId,
        min_award_floor: grantFloor,
        cost_sharing: costSharing,
        closing_date: closingDate,
        category_of_funding:
          fundingCategory.length === 0
            ? ["all"]
            : fundingCategory.map((c) => c.value),
      }),
    {
      onSuccess: (new_calls) => {
        setIsShowingResults(true);
        setHasGivenFeedback(false);
        mutate(
          `get_project_calls/${projectId}`,
          (old_calls) => {
            if (typeof new_calls === "string") {
              return { ...old_calls, recommendedCalls: [] };
            } else {
              return { ...old_calls, recommendedCalls: new_calls };
            }
          },
          { revalidate: false }
        );
      },
    }
  );

  const handleClosingDate = (newDate) => {
    const formattedDate = format(newDate, "yyyy-MM-dd");
    setClosingDate(formattedDate);
  };

  if (isFindCallsLoading) return <LoadingPage />;

  // useEffect(() => {
  //   if (calls?.recommendedCalls?.length > 0) {
  //     toast({
  //       duration: 10000,
  //       title: "Were these recommendations helpful?",
  //       description: "",
  //       action: (
  //         <>
  //           <ToastAction altText="Yes" onClick={() => provideFeedback(true)}>
  //             <IoMdThumbsUp />
  //           </ToastAction>
  //           <ToastAction altText="No" onClick={() => provideFeedback(false)}>
  //             <IoMdThumbsDown />
  //           </ToastAction>
  //         </>
  //       ),
  //     });
  //   }
  // }, [calls]);

  return (
    <>
      <div className={"flex flex-wrap gap-x-4 gap-y-2"}>
        <div className="flex flex-col w-40">
          <div>
            <Tooltip
              trigger={<span className="text-base underline">Min. Award</span>}
              content="We will only search for calls that have an award that is higher than the value you provide here."
            />
          </div>
          <Input
            className="w-full pl-1"
            type="number"
            value={grantFloor}
            onChange={(v) => setGrantFloor(v)}
          />
        </div>

        <div className="flex flex-col">
          <span className="text-base underline">Earliest Closing Date:</span>
          <DatePicker value={closingDate} onChange={handleClosingDate} />
        </div>
        <div className="flex flex-col">
          <Tooltip
            trigger={<span className="text-base underline">Cost Sharing:</span>}
            content="This filter indicates if you want to search for calls with a Cost Sharing or Matching requirement. If turned off, we will only search for ones without. If turned on, we will search for either."
          />
          <Switch
            checked={costSharing}
            onClick={() => {
              setCostSharing((p) => !p);
            }}
          ></Switch>
        </div>
        <div className="flex flex-col w-full">
          <div>
            <Tooltip
              trigger={
                <div className="text-sm underline">Funding Category:</div>
              }
              content="This filter will only search for calls if they belong to any of the selected categories. Leave blank if you would like to search for any category."
            />
          </div>

          <Select
            className="min-w-12 p-0 text-sm border-input rounded-md border-[1px] cursor-default py-1"
            menuPosition="fixed"
            classNames={{
              container: ({ isFocused }) =>
                `${
                  isFocused
                    ? " ring-2 ring-offset-background ring-offset-2 ring-ring"
                    : ""
                }`,
            }}
            styles={{
              control: () => ({ display: "flex" }),
            }}
            closeMenuOnSelect={false}
            isClearable
            isMulti
            onChange={(newValue) => setFundingCategory(newValue)}
            options={fundingOptions}
            value={fundingCategory}
            placeholder={"Leave empty to search all categories"}
          />
        </div>
      </div>
      <div className="flex gap-x-2">
        <button
          className="btn-offblack"
          onClick={findCalls}
          disabled={isFindCallsLoading}
        >
          <span>Search for relevant calls</span>
        </button>
        {calls?.recommendedCalls?.length > 0 && (
          <button
            className="underline hover:text-fuchsia"
            onClick={() => setIsShowingResults(true)}
          >
            See current results
          </button>
        )}
      </div>
    </>
  );
}
