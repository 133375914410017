import React from "react";
import ReactDOM from "react-dom/client";
import {
  createRoutesFromElements,
  createBrowserRouter,
  RouterProvider,
  Route,
} from "react-router-dom";

import "./index.css";

// Layouts
import { LoginLayout, Layout, HomeNavbar, ProjectNavbar } from "layouts";
import { LoginLayoutLoader } from "layouts/LoginLayout";
import { LayoutLoader } from "layouts/Layout";

import DeleteAccount from "pages/account/delete/Delete";

// Login Pages
import Login from "pages/account/login/Login";
import Register from "pages/account/register/Register";
import ForgotPassword from "pages/account/forgot-password/ForgotPassword";
import VerifyEmail from "pages/account/verify-email/VerifyEmail";
import ConfirmEmail from "pages/account/confirm-email/ConfirmEmail";
import ResetPassword from "pages/account/reset-password/ResetPassword";

// Home Pages
import Home from "pages/home/Home";
import UserBookmarkedCalls from "pages/profile/bookmarked-calls/UserBookmarkedCalls";
// import SearchCalls from "pages/calls/search-calls/SearchCalls";
import Profile from "pages/profile/about-you/Profile";
import Project from "pages/project/dashboard/Project";
import GuidelinesAssistant from "pages/guidelines-assistant/GuidelinesAssistant";
import Error from "pages/error/Error";
import Error404 from "pages/Error404";

// Idea Space Pages
import General from "pages/project/ideation-space/research-idea/ResearchIdea";
import BackgroundMaterial from "pages/project/ideation-space/background-material/BackgroundMaterial";
import Brainstorming from "pages/project/ideation-space/brainstorm-ideas/Brainstorming";
// import IdeaSpaceCalls from "pages/ideation-space/calls/Calls";

// Admin Space Pages
import ActionItems from "pages/project/admin-space/action-items/ActionItems";
import DocumentChecklist from "pages/project/admin-space/document-checklist/DocumentChecklist";

// Grant Space Pages
import BookmarkedCalls from "pages/project/grant-space/bookmarks/BookmarkedCalls";
import SearchCalls from "pages/project/grant-space/search-calls/SearchCalls";
import RecommendedCalls from "pages/project/grant-space/recommended-calls/RecommendedCalls";

import { SWRConfig } from "swr";
import SelectCall from "pages/project/grant-space/select-call/SelectCall";
import ListReviews from "pages/project/review-space/list-reviews/ListReviews";
import ProposalReview from "pages/project/review-space/file-review/ProposalReview";

import WriteSpace from "pages/project/write-space/WriteSpace";

import BrainstormSessionList from "pages/project/ideation-space/brainstorm-session/session-list/BrainstormSessionList";
import BrainstormSession from "pages/project/ideation-space/brainstorm-session/session/BrainstormSession";
import * as Toast from "@radix-ui/react-toast";
import { Toaster } from "@/components/ui/toaster";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route errorElement={<Error />}>
      <Route path="*" element={<Error404 />} />
      <Route element={<Layout navbar={<HomeNavbar />} />} loader={LayoutLoader}>
        <Route path="/" element={<Home />} />
        <Route path="/profile" element={<Profile />} />
        <Route
          path="/profile/bookmarked-calls"
          element={<UserBookmarkedCalls />}
        />
        <Route path="/calls/search-calls" element={<SearchCalls />} />
        <Route
          path="/calls/guidelines-assistant"
          element={<GuidelinesAssistant />}
        />
      </Route>
      <Route
        element={<Layout navbar={<ProjectNavbar />} />}
        loader={LayoutLoader}
      >
        <Route path="/project/:projectId" element={<Project />} />
        <Route
          path="/project/:projectId/ideation-space"
          element={<General />}
        />
        <Route
          path="/project/:projectId/ideation-space/background-material"
          element={<BackgroundMaterial />}
        />
        <Route
          path="/project/:projectId/ideation-space/brainstorming"
          element={<Brainstorming />}
        />
        <Route
          path="/project/:projectId/ideation-space/brainstorm-session"
          element={<BrainstormSessionList />}
        />
        <Route
          path="/project/:projectId/ideation-space/brainstorm-session/:sessionId"
          element={<BrainstormSession />}
        />
        <Route
          path="/project/:projectId/admin-space"
          element={<ActionItems />}
        />
        <Route
          path="/project/:projectId/admin-space/document-checklist"
          element={<DocumentChecklist />}
        />
        <Route
          path="/project/:projectId/grant-space/bookmarks"
          element={<BookmarkedCalls />}
        />
        <Route
          path="/project/:projectId/grant-space/selected-call"
          element={<SelectCall />}
        />
        <Route
          path="/project/:projectId/grant-space/search-calls"
          element={<SearchCalls />}
        />
        <Route
          path="/project/:projectId/grant-space/recommended-calls"
          element={<RecommendedCalls />}
        />
        <Route
          path="/project/:projectId/review-space"
          element={<ListReviews />}
        />
        <Route
          path="/project/:projectId/review-space/:fileId"
          element={<ProposalReview />}
        />
        {/* <Route
          path="/project/:projectId/write-space"
          element={<WriteSpace />}
        /> */}
      </Route>
      <Route element={<LoginLayout />} loader={LoginLayoutLoader}>
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/delete" element={<DeleteAccount />} />
        <Route path="/verify-email" element={<VerifyEmail />} />
        <Route path="/identities/confirm" element={<ConfirmEmail />} />
        <Route path="/identities/reset_password" element={<ResetPassword />} />
      </Route>
    </Route>
  )
);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        // revalidateOnMount: false,
        revalidateIfStale: false,
        revalidateOnFocus: false,
        refreshInterval: 0,
        shouldRetryOnError: false,
      }}
    >
      <RouterProvider router={router} />
    </SWRConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
