import {
  create_followup_session,
  create_new_brainstorm_session,
  delete_session,
  get_brainstorm_sessions,
} from "api";
import { ErrorPage, LoadingPage, LoadingSpinner, Title } from "components";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "@/components/ui/pagination";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/components/ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "@/components/ui/dialog";
import useSWR, { mutate } from "swr";
import { useState } from "react";
import { Input } from "@/components/styled/input";
import { Select } from "@/components/styled/select";
import useSWRMutation from "swr/mutation";
// import { Dialog } from "@/components/styled/dialog";
// import { DialogClose } from "@/components/ui/dialog";
import { MdDelete } from "react-icons/md";
import { remove_timestamp } from "@/lib/utils";
import { PageHelp } from "@/components/styled/pageHelp";
import { FaEllipsisV } from "react-icons/fa";
import { TiArrowForward } from "react-icons/ti";
import { DialogClose } from "@radix-ui/react-dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";

const agent_types = [
  { label: "Sociology", value: "sociology" },
  { label: "Computer Science", value: "computer science" },
  { label: "Data Science", value: "data science" },
  { label: "Economics", value: "economics" },
  { label: "Mechanical Engineering", value: "mechanical engineering" },
  { label: "Chemical Engineering", value: "chemical engineering" },
  { label: "Aerospace Engineering", value: "aerospace engineering" },
  { label: "Statistics", value: "statistics" },
  { label: "Mathematics", value: "mathematics" },
  { label: "Civil Engineering", value: "civil engineering" },
  { label: "Medical", value: "medical" },
  { label: "Education", value: "education" },
  { label: "Computer Biology", value: "biology" },
  { label: "Chemistry", value: "chemistry" },
  { label: "Physics", value: "physics" },
];

export default function BrainstormSessionList() {
  const { projectId } = useParams();

  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();
  const num_sessions_per_page = 15;
  const page = Math.max(1, searchParams.get("page"));

  const {
    data: brainstormSessions,
    isLoading: isGettingBrainstormSessions,
    error: brainstormSessionsError,
    isValidating: isValidatingSessions,
  } = useSWR(`get_brainstorm_sessions/${projectId}page=${page}`, () =>
    get_brainstorm_sessions({
      project_id: projectId,
      start_number: (page - 1) * num_sessions_per_page,
      num_session: num_sessions_per_page,
    })
  );

  const {
    data: finishedSessions,
    isLoading: isGettingFinishedSessions,
    error: finishedSessionsError,
    isValidating: isValidatingFinishedSessions,
  } = useSWR(`get_finished_brainstorm_sessions/${projectId}`, () =>
    get_brainstorm_sessions({
      project_id: projectId,
      start_number: 0,
      num_session: 15,
      finished: true,
    })
  );

  const {
    isMutating: isCreatingBrainstormSession,
    error: createBrainstormSessionError,
    trigger: createBrainstormSession,
  } = useSWRMutation(
    `create_brainstorm_session`,
    () => {
      if (followupSessionId === "new") {
        return create_new_brainstorm_session({
          project_id: projectId,
          name: sessionTitle,
          agent_1_background: agent1Background,
          agent_2_background: agent2Background,
        });
      } else {
        return create_followup_session({
          project_id: projectId,
          session_id: followupSessionId,
          name: sessionTitle,
          agent_1_background: agent1Background,
          agent_2_background: agent2Background,
        });
      }
    },
    {
      onSuccess: (d) => {
        const { session_id } = d;
        navigate(
          `/project/${projectId}/ideation-space/brainstorm-session/${session_id}`
        );
        mutate((key) =>
          key?.startsWith(`get_brainstorm_sessions/${projectId}`)
        );
      },
    }
  );

  const {
    isMutating: isDeletingSession,
    error: deleteSessionError,
    trigger: deleteSession,
  } = useSWRMutation(
    `delete_session`,
    () =>
      delete_session({
        session_id: deleteSessionInfo.session_id,
      }),
    {
      onSuccess: () => {
        setIsDeleteDialogOpen(false);
        mutate(
          (key) =>
            key?.startsWith(`get_brainstorm_sessions/${projectId}`) ||
            key === `get_finished_brainstorm_sessions/${projectId}`,
          undefined,
          {
            revalidate: true,
          }
        );
      },
    }
  );

  const [isCreateSessionDialogOpen, setIsCreateSessionDialogOpen] =
    useState(false);
  const [sessionTitle, setSessionTitle] = useState("");
  const [agent1Background, setAgent1Background] = useState("");
  const [agent2Background, setAgent2Background] = useState("");
  const [followupSessionId, setFollowupSessionId] = useState("new");

  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const [deleteSessionInfo, setDeleteSessionInfo] = useState({
    title: "",
    session_id: "",
  });

  if (
    isGettingBrainstormSessions ||
    isGettingFinishedSessions ||
    isValidatingSessions ||
    isValidatingFinishedSessions
  )
    return <LoadingPage />;
  if (brainstormSessionsError || finishedSessionsError) return <ErrorPage />;

  const isLastPage = brainstormSessions?.length < num_sessions_per_page;
  const increasePagination = () => {
    if (!isLastPage) setSearchParams({ page: page + 1 });
  };
  const decreasePagination = () => {
    if (page !== 1) setSearchParams({ page: Math.max(1, page - 1) });
  };

  return (
    <>
      <div className="bg-white flex flex-col grow rounded-lg h-full p-4 gap-y-2">
        <div className="flex justify-between">
          <Title className="text-4xl" value="Brainstorm Sessions" />
          <PageHelp
            title="Ideation Space"
            content={
              <div className="flex flex-col gap-y-4 text-black">
                <div>
                  The Ideation Space is designed to help you refine your
                  research idea.
                </div>
                <div>
                  <h2 className="font-semibold">Research Idea</h2>
                  Here you will tell us a more about what idea you would like to
                  get funded.
                </div>
                <div>
                  <h2 className="font-semibold">Background Material</h2>
                  Enhance our understanding of your idea by searching for and
                  linking relevant papers. You can search for papers using Arxiv
                  or Semantic Scholar. Note: adding papers here will help us to
                  recommend better calls for you in the future.
                </div>
                <div>
                  <h2 className="font-semibold">Brainstorming Ideas</h2>
                  Use this section to further develop your idea, take notes, and
                  explore new perspectives. You can manually add ideas to the
                  brainstorming list, or let us generate suggestions for you.
                  Ideas are generated based on your Research Idea, Description,
                  and any Background Materials you've provided.
                </div>
                <div>
                  <h2 className="font-semibold">Brainstorming Sessions</h2>
                  With Sessions, you can easily create brainstorming rooms with
                  experts from various backgrounds. Each session lasts a few
                  days, and after it ends, we'll provide a summary of the
                  discussion. You can start new sessions based on previous
                  topics or begin with a fresh idea.
                </div>
              </div>
            }
          />
        </div>
        <div className="text-base">
          Welcome to Sessions — your brainstorming copilot. Easily create rooms
          with experts from various fields, with each session lasting just a few
          days. When it's over, we'll provide a quick recap. Build on past ideas
          or start fresh. Give it a try and see what new ideas you come up with.
        </div>
        <div className="flex justify-end">
          <button
            className="btn-fuchsia text-base"
            onClick={() => setIsCreateSessionDialogOpen(true)}
          >
            Create a new session
          </button>
        </div>

        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Session Name</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Created</TableHead>
              <TableHead></TableHead>
            </TableRow>
          </TableHeader>

          <TableBody>
            {brainstormSessions?.length === 0 && (
              <TableRow>
                <TableCell colSpan={4} className="text-center">
                  There are no sessions.
                </TableCell>
              </TableRow>
            )}
            {brainstormSessions?.map((session) => (
              <TableRow key={session.session_id} className="group">
                <TableCell
                  className="hover:text-fuchsia hover:underline hover:cursor-pointer"
                  onClick={() =>
                    navigate(
                      `/project/${projectId}/ideation-space/brainstorm-session/${session.session_id}`
                    )
                  }
                >
                  {session.title}
                </TableCell>
                <TableCell>
                  {session.finished ? "Done" : "In Progress"}
                </TableCell>
                <TableCell>{remove_timestamp(session.creation_time)}</TableCell>
                <TableCell onClick={(e) => e.stopPropagation()}>
                  <DropdownMenu>
                    <DropdownMenuTrigger>
                      <FaEllipsisV className="hover:text-fuchsia" />
                    </DropdownMenuTrigger>
                    <DropdownMenuContent>
                      {session.finished && (
                        <DropdownMenuItem>
                          <div
                            className="flex gap-x-2"
                            onClick={() => {
                              setFollowupSessionId(session.session_id);
                              setIsCreateSessionDialogOpen(true);
                            }}
                          >
                            <TiArrowForward className="h-4 w-4" />
                            Start Followup Session
                          </div>
                        </DropdownMenuItem>
                      )}
                      <DropdownMenuItem
                        className="flex gap-x-2"
                        onClick={() => {
                          setDeleteSessionInfo({
                            title: session.title,
                            session_id: session.session_id,
                          });
                          setIsDeleteDialogOpen(true);
                        }}
                      >
                        <MdDelete className="h-4 w-4" />
                        Delete
                      </DropdownMenuItem>
                    </DropdownMenuContent>
                  </DropdownMenu>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>

        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                className="aria-disabled:text-gray-500 aria-disabled:hover:bg-inherit cursor-pointer aria-disabled:cursor-default"
                aria-disabled={page === 1}
                onClick={decreasePagination}
              />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink className="cursor-default hover:bg-inherit">
                {page}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationNext
                className="aria-disabled:text-gray-500 aria-disabled:hover:bg-inherit cursor-pointer aria-disabled:cursor-default"
                aria-disabled={isLastPage}
                onClick={increasePagination}
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
      <Dialog
        open={isCreateSessionDialogOpen}
        onOpenChange={setIsCreateSessionDialogOpen}
      >
        <DialogContent className="max-w-lg">
          <DialogHeader>
            <DialogTitle>Create a Session</DialogTitle>
            <DialogDescription></DialogDescription>
          </DialogHeader>
          <div className="flex flex-col gap-y-4">
            <Input
              placeholder="Session Title"
              value={sessionTitle}
              onChange={setSessionTitle}
            />

            <Select
              options={agent_types}
              value={agent1Background}
              placeholder="Researcher 1 Expertise"
              onChange={setAgent1Background}
            />

            <Select
              options={agent_types}
              value={agent2Background}
              placeholder="Researcher 2 Expertise"
              onChange={setAgent2Background}
            />
            <Select
              options={[
                { label: "Start from scratch", value: "new" },
                ...finishedSessions.map((session) => ({
                  label: session.title,
                  value: session.session_id,
                })),
              ]}
              value={followupSessionId}
              onChange={setFollowupSessionId}
              placeholder="Start a new session"
            />
            <div>
              <button
                onClick={createBrainstormSession}
                disabled={!agent1Background || !agent2Background}
                className="btn-fuchsia text-base"
              >
                Start Session
              </button>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent className="max-w-lg">
          <DialogHeader>
            <DialogTitle>
              Are you sure you want to delete this session?
            </DialogTitle>

            <DialogDescription className="text-center">
              "{deleteSessionInfo.title}"
            </DialogDescription>
          </DialogHeader>
          <div className="flex justify-between text-sm">
            <DialogClose className="hover:underline">Close</DialogClose>
            {isDeletingSession ? (
              <span className="flex gap-x-2 items-center text-red-500">
                Deleting <LoadingSpinner />
              </span>
            ) : (
              <button
                className="text-red-500 hover:underline"
                onClick={deleteSession}
              >
                Delete
              </button>
            )}
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}
