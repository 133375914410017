import { ScrollArea } from "@/components/ui/scroll-area";
import Call from "./Call";
import { useFeedback } from "stores/useFeedbackStore";
import { Toaster } from "@/components/ui/toaster";
import { ToastAction } from "@/components/ui/toast";
import { useToast } from "@/hooks/use-toast";
import { useEffect } from "react";
import { IoMdThumbsDown, IoMdThumbsUp } from "react-icons/io";
import useSWRMutation from "swr/mutation";
import { provide_feedback_for_recommended_calls } from "api/grant_space";

export default function Results({ projectId, calls, setIsShowingResults }) {
  const { hasGivenFeedback, setHasGivenFeedback } = useFeedback(projectId);
  const { toast } = useToast();

  const { trigger: provideFeedback } = useSWRMutation(
    `provide_feedback_for_recommended_calls`,
    (key, { arg }) =>
      provide_feedback_for_recommended_calls({
        project_id: projectId,
        useful: arg,
      }),
    {
      onSuccess: () => setHasGivenFeedback(true),
    }
  );

  useEffect(() => {
    if (!hasGivenFeedback) {
      toast({
        title: "Were these recommendations helpful?",
        action: (
          <>
            <ToastAction altText="Yes" onClick={() => provideFeedback(true)}>
              <IoMdThumbsUp />
            </ToastAction>
            <ToastAction altText="No" onClick={() => provideFeedback(false)}>
              <IoMdThumbsDown />
            </ToastAction>
          </>
        ),
      });
    }
  }, []);

  return (
    <>
      <div className="flex justify-center">
        <button
          className="btn-fuchsia text-base rounded-full"
          onClick={() => setIsShowingResults(false)}
        >
          Run a new search
        </button>
      </div>
      <ScrollArea className="p-4">
        <div className="flex flex-col gap-y-8">
          {calls?.recommendedCalls?.length > 0 ? (
            calls.recommendedCalls.map((call) => (
              <Call {...call} projectId={projectId} key={call.call_id} />
            ))
          ) : (
            <span>
              There are no recommended calls. Please run another search.
            </span>
          )}
        </div>
      </ScrollArea>
      <Toaster />
    </>
  );
}
