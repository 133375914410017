import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";

/**
 *
 * @param {*} tabs list of label, value, and content
 */
function t({ tabs, defaultValue }) {
  return (
    <Tabs className="h-full flex flex-col overflow-y-auto" defaultValue={defaultValue}>
      <TabsList className="w-fit">
        {tabs?.map((tab) => (
          <TabsTrigger key={`trigger-${tab.value}`} value={tab.value}>
            {tab.label}
          </TabsTrigger>
        ))}
      </TabsList>
      {tabs?.map((tab) => (
        <TabsContent asChild key={`content-${tab.value}`} value={tab.value}>
          {tab.content}
        </TabsContent>
      ))}
    </Tabs>
  );
}
export { t as Tabs };
