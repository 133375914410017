import { ErrorPage, LoadingPage, Title } from "components";
import { Tooltip } from "@/components/styled/tooltip";
import TopicIdea from "./TopicIdea";
import { get_brainstorm, get_project, get_profile } from "api";
import { useParams } from "react-router-dom";
import useSWR from "swr";
import { TextArea, TextInput, LoadingSpinner } from "components";
// import { Dialog, DialogPanel, DialogTitle } from "@headlessui/react";
import { Dialog } from "@/components/styled/dialog";
import { useState } from "react";
import useSWRMutation from "swr/mutation";
import { mutate } from "swr";
import { add_brainstorm_ideas, generate_brainstorm_ideas } from "api";
import { MdCheckBox } from "react-icons/md";
import { MdCheckBoxOutlineBlank } from "react-icons/md";
import { PageHelp } from "@/components/styled/pageHelp";

export default function Brainstorming() {
  const { projectId } = useParams();

  const {
    data,
    isLoading,
    error: isError,
  } = useSWR(`/project/idea/brainstorm/${projectId}`, () =>
    get_brainstorm({ project_id: projectId })
  );

  const {
    data: project,
    isLoading: isProjectLoading,
    error: isProjectError,
  } = useSWR(`get_project/${projectId}`, () =>
    get_project({ project_id: projectId })
  );

  const {
    data: profile,
    isLoading: isProfileLoading,
    error: isProfileError,
  } = useSWR(`get_profile`, () => get_profile());

  // Manually Adding Idea
  const [isAddIdeaOpen, setIsAddIdeaOpen] = useState(false);
  const [idea, setIdea] = useState("");
  const [description, setDescription] = useState("");

  const {
    isMutating,
    error: isUpdateError,
    trigger,
  } = useSWRMutation(
    `add_brainstorm_ideas`,
    async () => {
      await add_brainstorm_ideas({
        project_id: projectId,
        brainstorm_ideas: [{ item: idea, description: description }],
        ai_generated: false,
      });
    },
    {
      onSuccess: () => {
        setIsAddIdeaOpen(false);
        setIdea("");
        setDescription("");
        mutate(`/project/idea/brainstorm/${projectId}`);
      },
    }
  );

  // Generate Brainstorming Ideas
  const [selectedIdeas, setSelectedIdeas] = useState([]);

  const {
    data: generatedIdeas,
    isLoading: isGeneratedIdeasLoading,
    isValidating: isGenerateIdeasValidating,
    error: generatedIdeasError,
    mutate: generateNewIdeas,
  } = useSWR(`/process/idea/brainstorm/${projectId}`, () =>
    generate_brainstorm_ideas({ project_id: projectId })
  );

  const {
    isMutating: isAddingGeneratedIdeas,
    error: addGenerateIdeasError,
    trigger: addGeneratedIdeas,
  } = useSWRMutation(
    `update_brainstorm_ideas`,
    async () => {
      await add_brainstorm_ideas({
        project_id: projectId,
        brainstorm_ideas: selectedIdeas,
        ai_generated: true,
      });
    },
    {
      onSuccess: (d) => {
        mutate(`/project/idea/brainstorm/${projectId}`);

        mutate(
          `/process/idea/brainstorm/${projectId}`,
          (d) => ({
            ...d,
            items: d.items.filter(
              ({ description, item }) =>
                !selectedIdeas.find(
                  (idea) =>
                    idea.description === description && idea.item === item
                )
            ),
          }),
          { revalidate: false }
        );
        setSelectedIdeas([]);
      },
    }
  );

  const handleToggleIdea = ({ description, item }) => {
    const index = selectedIdeas.findIndex(
      (e) => e.item === item && e.description === description
    );
    if (index >= 0) {
      setSelectedIdeas((p) =>
        p.filter((e) => !(e.item === item && e.description === description))
      );
    } else {
      let newObj = { description, item };
      setSelectedIdeas((p) => [...p, newObj]);
    }
  };

  if (isLoading || isProjectLoading || isProfileLoading) return <LoadingPage />;
  if (isError || isProjectError || isProfileError) return <ErrorPage />;

  const hasEditorAccess =
    project?.users.find((p) => p.user_id === profile.user_id).relationship !==
    "viewer";
  const ideas =
    data?.items
      .filter((i) => i.brainstorm_id)
      .toSorted((a, b) => a.brainstorm_id.localeCompare(b.brainstorm_id)) || [];

  return (
    <div className="bg-white grow rounded-lg h-full p-4">
      <div className="flex flex-col h-full">
        <div className="flex justify-between">
          <Title className="text-4xl" value="Brainstorm Ideas" />
          <PageHelp
            title="Ideation Space"
            content={
              <div className="flex flex-col gap-y-4 text-black">
                <div>
                  The Ideation Space is designed to help you refine your
                  research idea.
                </div>
                <div>
                  <h2 className="font-semibold">Research Idea</h2>
                  Here you will tell us a more about what idea you would like to
                  get funded.
                </div>
                <div>
                  <h2 className="font-semibold">Background Material</h2>
                  Enhance our understanding of your idea by searching for and
                  linking relevant papers. You can search for papers using Arxiv
                  or Semantic Scholar. Note: adding papers here will help us to
                  recommend better calls for you in the future.
                </div>
                <div>
                  <h2 className="font-semibold">Brainstorming Ideas</h2>
                  Use this section to further develop your idea, take notes, and
                  explore new perspectives. You can manually add ideas to the
                  brainstorming list, or let us generate suggestions for you.
                  Ideas are generated based on your Research Idea, Description,
                  and any Background Materials you've provided.
                </div>
                <div>
                  <h2 className="font-semibold">Brainstorming Sessions</h2>
                  With Sessions, you can easily create brainstorming rooms with
                  experts from various backgrounds. Each session lasts a few
                  days, and after it ends, we'll provide a summary of the
                  discussion. You can start new sessions based on previous
                  topics or begin with a fresh idea.
                </div>
              </div>
            }
          />
        </div>
        <div className="text-base">
          Brainstorming Ideas is a dedicated space for users to organize,
          develop, and refine their thoughts. You can add an unlimited number of
          ideas, making it easy to capture all your creative insights. If you
          ever need inspiration or wish to explore new directions, our platform
          can assist by generating relevant ideas tailored to your current
          focus.
        </div>
        <div className="flex flex-col gap-y-8 overflow-y-auto grow shrink">
          {ideas?.length > 0 ? (
            ideas.map((item, i) => (
              <TopicIdea
                hasEditorAccess={hasEditorAccess}
                key={item.brainstorm_id}
                projectId={projectId}
                index={i + 1}
                {...item}
              />
            ))
          ) : (
            <div className="border-dashed border-4 rounded-md flex flex-col justify-center items-center grow">
              <div className="font-semibold">
                You haven't added any brainstorming ideas yet.
              </div>
            </div>
          )}
        </div>
        {hasEditorAccess && (
          <>
            <div className="flex gap-x-4">
              <Dialog
                trigger={
                  <div className="btn-offblack text-2xl">Generate Ideas</div>
                }
                title="Add a brainstorming idea"
                asChild
                contentClassName="max-w-2xl"
                content={
                  <div className="flex flex-col gap-y-4">
                    <div className="overflow-y-scroll max-h-96 bg-light-grey p-2 rounded-md">
                      {generatedIdeas?.items?.length === 0 && (
                        <span>There are no more generated ideas</span>
                      )}
                      {isGeneratedIdeasLoading || isGenerateIdeasValidating ? (
                        <span className="flex items-center gap-x-2">
                          <LoadingSpinner /> Generating ideas. This may take a
                          few seconds...
                        </span>
                      ) : generatedIdeasError ? (
                        <span>
                          There was an error generating ideas. Please try again.
                        </span>
                      ) : (
                        <div className="flex flex-col gap-y-8 p-1">
                          {generatedIdeas?.items.map((idea, i) => {
                            const isSelected = selectedIdeas.find(
                              (e) =>
                                e.item === idea.item &&
                                e.description === idea.description
                            );
                            return (
                              <button
                                onClick={() =>
                                  handleToggleIdea({
                                    description: idea.description,
                                    item: idea.item,
                                  })
                                }
                                className={
                                  "flex flex-col group text-left text-black ring-fuchsia hover:ring-2 hover:bg-light-pink p-2 rounded-md " +
                                  (isSelected && "bg-light-pink")
                                }
                                key={i}
                              >
                                <span className="group-hover:underline font-bold inline-flex items-center">
                                  {isSelected ? (
                                    <MdCheckBox />
                                  ) : (
                                    <MdCheckBoxOutlineBlank />
                                  )}
                                  {idea.item}
                                </span>
                                <span className="">{idea.description}</span>
                              </button>
                            );
                          })}
                        </div>
                      )}
                    </div>

                    <div className="flex justify-between gap-4 w-full">
                      <div className="flex gap-4">
                        <button
                          disabled={
                            isGeneratedIdeasLoading || isGenerateIdeasValidating
                          }
                          className="btn-fuchsia place-self-end self-end justify-self-end"
                          onClick={generateNewIdeas}
                        >
                          Regenerate Ideas
                        </button>
                      </div>
                      {isAddingGeneratedIdeas || isGenerateIdeasValidating ? (
                        <button
                          disabled={true}
                          className="btn-fuchsia flex items-center gap-x-2"
                        >
                          Updating <LoadingSpinner />
                        </button>
                      ) : (
                        <button
                          disabled={selectedIdeas.length === 0}
                          className="btn-fuchsia"
                          onClick={addGeneratedIdeas}
                        >
                          Add ({selectedIdeas.length})
                        </button>
                      )}
                    </div>
                    {addGenerateIdeasError && (
                      <span className="text-red-500">
                        There was an error updating your ideas. Please try
                        again.
                      </span>
                    )}
                  </div>
                }
              />

              <Dialog
                trigger={
                  <div className="text-fuchsia hover:underline">
                    Add your own idea
                  </div>
                }
                title="Add a brainstorming idea"
                contentClassName="max-w-2xl"
                content={
                  <div className="flex flex-col gap-y-2">
                    <TextInput input={idea} setInput={setIdea} title={"Idea"} />
                    <TextArea
                      input={description}
                      setInput={setDescription}
                      title={"Description"}
                    />
                    <div className="flex gap-4 w-full">
                      {isMutating ? (
                        <button
                          disabled={true}
                          className="btn-fuchsia flex items-center gap-x-2"
                        >
                          Updating <LoadingSpinner />
                        </button>
                      ) : (
                        <button
                          disabled={
                            idea.length === 0 || description.length === 0
                          }
                          className="btn-fuchsia"
                          onClick={trigger}
                        >
                          Add Idea
                        </button>
                      )}
                    </div>
                    {isUpdateError && (
                      <span className="text-red-500">
                        There was an error adding your idea. Please try again.
                      </span>
                    )}
                  </div>
                }
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
