import { Select } from "@/components/styled/select";
import { get_file } from "api/review_space";
import { LoadingPage } from "components";
import { useState } from "react";
import Markdown from "react-markdown";
import useSWR from "swr";

export default function AgentFeedback({
  url,
  agent_feedback,
  mutateFileReview,
}) {
  const [filterByReviewer, setFilterByReviewer] = useState("Reviewer 1");

  // Check if file is .pdf or .docx
  const isPDF = url.includes(".pdf");
  const encodedBucketUrl = encodeURIComponent(url);

  const {
    data: file,
    isLoading: isGettingFile,
    error: getFileError,
  } = useSWR(
    url,
    () =>
      get_file({
        url: url,
      }),
    {
      onError: (err) => {
        if (err?.response?.status === 400) {
          mutateFileReview();
        }
      },
    }
  );

  if (isGettingFile) return <LoadingPage />;

  // If PDF, we use google doc viewer. If docx, use microsoft office web viewer
  const encodedUrl = isPDF
    ? url
    : `https://view.officeapps.live.com/op/embed.aspx?src=${encodedBucketUrl}&embedded=true`;
  console.log(encodedUrl);

  return (
    <div className="flex w-full h-full gap-x-2 pb-24 pt-4">
      <iframe className="h-full" src={encodedUrl} height="500" width="100%" />

      <div className="flex flex-col h-full text-sm w-6/12 gap-y-2">
        <Select
          value={filterByReviewer}
          onChange={(v) => setFilterByReviewer(v)}
          options={[
            { label: "Reviewer 1", value: "Reviewer 1" },
            { label: "Reviewer 3", value: "Reviewer 3" },
            { label: "All", value: "All" },
          ]}
        />
        <div className="flex flex-col h-full gap-y-16 overflow-y-scroll text-sm">
          {filterByReviewer === "All"
            ? [
                agent_feedback.map((feedback, i) => (
                  <div className="bg-beige p-2 rounded-md" key={i}>
                    <div className="text-lg">{feedback.reviewer}</div>
                    <div>{feedback.title}</div>
                    <div>Page {feedback.page}</div>
                    <Markdown>{feedback.feedback}</Markdown>
                  </div>
                )),
              ]
            : [
                agent_feedback
                  .filter((feedback) => feedback.reviewer === filterByReviewer)
                  .map((feedback, i) => (
                    <div className="bg-beige p-2 rounded-md" key={i}>
                      <div className="text-lg">{feedback.reviewer}</div>
                      <div>{feedback.title}</div>
                      <div>Page {feedback.page}</div>
                      <Markdown>{feedback.feedback}</Markdown>
                    </div>
                  )),
              ]}
        </div>
      </div>
    </div>
  );
}
